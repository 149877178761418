import Cookies from 'js-cookie'
import {getAuthUrl} from '../api/index.js'
import router from '../router'
import wx from 'jweixin-npm'

export const setEmail = (email) => localStorage.setItem("exhibition-scan-email", email)
export const getEmail = () => localStorage.getItem("exhibition-scan-email")
/**
 * 是否是手机号
 * @param {*} $poneInput 
 * @returns 
 */
export const isMobile = function (val) {
    var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (!myreg.test(val)) {
        return false;
    } else {
        return true;
    }
}

/**
 * 身份证号校验
 * @param {*} idcard 
 * @returns 
 */
export const validateIdCard = function (idcard) {
    // 判断如果传入的不是一个字符串，则转换成字符串
    idcard = typeof idcard === 'string' ? idcard : String(idcard);
    //正则表达式验证号码的结构
    let regx = /^[\d]{17}[0-9|X|x]{1}$/;
    if (regx.test(idcard)) {
      // 验证前面17位数字，首先定义前面17位系数
      let sevenTeenIndex = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      // 截取参数前17位
      let front_seventeen = idcard.slice(0, 17);
      // 截取第18位
      let eighteen = idcard.slice(17, 18);
      // 这里如果是X要转换成小写，如果是数字在这里是字符串类型,则转换成数字类型，好做判断
      eighteen = isNaN(parseInt(eighteen)) ? eighteen.toLowerCase() : parseInt(eighteen);
      // 定义一个变量计算系数乘积之和余数
      let remainder = 0;
      //利用循环计算前17位数与系数乘积并添加到一个数组中
      // charAt()类似数组的访问下标一样，访问单个字符串的元素,返回的是一个字符串因此要转换成数字
      for (let i = 0; i < 17; i++) {
        remainder = (remainder += parseInt(front_seventeen.charAt(i)) * sevenTeenIndex[i]) % 11;
      }
      //余数对应数字数组
      let remainderKeyArr = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
      // 取得余数对应的值
      let remainderKey = remainderKeyArr[remainder] === 'X' ? remainderKeyArr[remainder].toLowerCase() : remainderKeyArr[remainder];
      // 如果最后一位数字对应上了余数所对应的值，则验证合格，否则不合格,
      // 由于不确定最后一个数字是否是大小写的X，所以还是都转换成小写进行判断
      if (eighteen === remainderKey) {
        return true;
      } else {
        return false
      }
    } else {
        return false
    }
  }

/**
 * 是否是邮箱
 * @param {*} strEmail 
 * @returns 
 */
export const isEmail = function (strEmail) {
    if (strEmail.search(/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/) != -1) {
        return true;
    } else {
        return false;
    }
}
/**
 * 是否为空
 * @param {*} value 
 * @returns 
 */
export const isEmpty = function (value){
    return value == undefined || value == null || value == '';
}

/**
 * 产品列表图片path
 * @param {*} url 
 * @returns 
 */
export const ListPic = function (url) {
    if (url) {
        return url + '?x-oss-process=style/P_List_Pic&t=1662028314054'
    }
    return url
}

const TOKEN_KEY = 'GroupPurchaseFactory'

export const setToken = (token, time = null) => {
    if (token) {
        if (time) {
            Cookies.set(TOKEN_KEY, token, time)
        } else {
            Cookies.set(TOKEN_KEY, token)
        }
    } else {
      Cookies.remove(TOKEN_KEY)
    }
}

export const getToken = () => {
    const token = Cookies.get(TOKEN_KEY)
    if (token) return token
    else return false
}

// 当前是否在微信浏览器中
export const isWeiXin  = () => {
    var ua = window.navigator.userAgent.toLowerCase()
    if (ua.indexOf('micromessenger') > -1) {
      return true
    }
    return false
}
// 当前是否在移动端
export const isOnMobile = () => {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}

// 判断是否为iOS
export const isIOS = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    return isIOS
}

// 当前是否在小程序
export const isInMiniProgram = () => {
    return new Promise((resolve, reject) => {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            wx.miniProgram.getEnv(function (res) {
                if (res.miniprogram) {
                    // 小程序环境下逻辑
                    resolve(true)
                    return
                } else {
                    //非小程序环境下逻辑
                    resolve(false)
                    return
                }
            });
        } else {
            resolve(false)
            return
        }
        
    })
}

// 微信授权登录
export const wxInfoLogin = (redirect) => {
    var url = (process.env.NODE_ENV === "production" ? "/index/" : "/") + 'auth?target=' + encodeURIComponent(redirect || '/')
    getAuthUrl({url: url})
        .then(res => {
            if (res.code == 0) {
                window.location.href = res.data
            } else {
                router.push({name: 'auth'})
            }
        })
}

// 手机号中间4位替换位*号
export const replaceMobile = (mobile, length=4) => {
    if (mobile.length == 11) {
        return mobile.substr(0,3) + '*'.padStart(length, '*') + mobile.substr(7)
    }
    return mobile
}

//一维数组的差集
export const arrayAminusB = (arrA, arrB) => {
  return arrA.filter(v => !arrB.includes(v));
}

// 取区间随机数
export const getRand = (max, min) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

// 设置或判断app环境
export const isApp = (is_set = false, val = 1) => {
    let key = 'is_app'
    if (is_set) {
        localStorage.setItem(key, val)
    } else {
        let val = localStorage.getItem(key)
        return val && val > 0 ? true : false
    }
}

// 对象转url字符串
export const objectToUrl = (obj) => {
    var params = []
    Object.keys(obj).forEach((key) => {
      let value = obj[key]
      // 如果值为undefined我们将其置空
      if (typeof value === 'undefined') {
        value = ''
      }
      // 对于需要编码的文本（比如说中文）我们要进行编码
    //   params.push([key, encodeURIComponent(value)].join('='))
      params.push([key, value].join('='))
    })
    return params.join('&')
}