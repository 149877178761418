import TIM from 'tim-js-sdk'
import COS from 'cos-js-sdk-v5'

let options={
    // SDKAppID:1400534813, // 测试
    SDKAppID:1400432872, //正式的
};

let tim=TIM.create(options);
tim.setLogLevel(2);
tim.registerPlugin({'tim-upload-plugin':COS});

export {tim,TIM} 