import Vue from "vue";
import Vuex from "vuex";
import routerData from '@/store/module/routerData.js';
import user from '@/store/module/user.js';
import chat from '@/store/module/chat.js';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        routerData,
        user,
        chat
    }
});
