<template>
  <div class="contain">
    <div id="kefu-contain">
      <drag :id="'drag-contain'">
        <img id="customerService" @click="toChat" src="../assets/images/kefu-icon.png" class="kefu">
      </drag>
    </div>
  </div>
</template>

<script>
import Drag from './drag'
export default {
  name: 'kefu',
  components: {
    Drag
  },
  methods: {
    toChat() {
      this.$router.push(`/chat/detail?cid=702bdaeb36f0074f20ee43913873d56d&&cname=平台客服`)
    }
  }
}
</script>

<style lang="scss" scoped>
.contain{ 
    pointer-events: none;
    height:100vh;
    position: fixed;
    top: 300px;
    width: 100%;
    z-index: 999;
}
#kefu-contain{
    width: 100%;
    height: 100%;
    position: relative;
  #drag-contain{
    bottom: 420px;
    right: 0px;
    z-index: 99;
    pointer-events: auto;
  }
  img {
    width: 130px;
    height: 130px;
    display: block;
  }
}
</style>