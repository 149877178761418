import router from '@/router';
export default {
  namespaced: true,
  state: {
    cache: [],
  },
  getters: {

  },
  mutations: {
    removeCache(state, name){
      let index = state.cache.indexOf(name);
      if(index > -1){
        state.cache.splice(index, 1);
      }
    },
    addCache(state, name){
      state.cache.push(name);
    },
    setCache(state) {
      //遍历路由列表，把有meta.cache=true的缓存起来
      state.cache = router.options.routes.filter(currentValue=>{
        return currentValue.meta != undefined && currentValue.meta.cache != undefined && currentValue.meta.cache == true;
      }).map((currentValue) => currentValue.name);
    },
  },
  actions: {

  }
};
