export default {
  namespaced: true,
  state: {
    TimReady: false,
    currentChatId: ''
  },
  getters: {
    getTimReady: state => state.TimReady,
    currentChatId: state => state.currentChatId
  },
  mutations: {
    setTimReady(state, TimReady) {
      state.TimReady = TimReady;
    },
    setChatId(state, currentChatId) {
      state.currentChatId = currentChatId
    }
  },
  actions: {
    doChatId(context, chatId) {
      context.commit('setChatId', chatId)
    }
  }
};
