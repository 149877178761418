<template>
  <van-popup v-model="show" closeable close-icon="close" @close="close">
    <div>
      <img src="https://imgs.85804669.com/xiaoniao/jicai/popularize/holiday/20230117.jpg" class="img">
    </div>
  </van-popup>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      defalut: false
    }
  },
  data() {
    return {}
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    close() {
      localStorage.setItem("holiday_20230117",1);
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.img{
  display: block;
  width: 100%;
}
</style>