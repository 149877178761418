import { Toast } from 'vant';
import { userDetail } from "@/api/index.js";

export default {
    namespaced: true,
    state: {
        user: null,
    },
    getters: {
        getUser(state) {
            return state.user
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
    },
    actions: {
        initUser({ commit, state }, {reload, success, err} = { reload:false, success:()=>{}, err:()=>{} }) {
            if (reload || state.user == null) {
                userDetail({type: 'user'}).then(res => {
                    if (res.code == 0) {
                        commit('setUser', res.data);
                        if (success != undefined && typeof success == 'function') {
                            success();
                        }
                    } else {
                        Toast(res.msg);
                        if (err != undefined && typeof err == 'function') {
                            err(res.msg);
                        }
                    }
                });
            }
        }
    }
};
