import axios from '@/lib/axios'

// 签名
export const sign = () => {
  return axios.request({
      url: 'api/im/sign',
      method: 'post'
  });
};

// 聊天列表
export const chatList = () => {
  return axios.request({
      url: 'api/im/list',
      method: 'post'
  });
};

// 删除聊天
export const chatDel = (data) => {
  return axios.request({
      url: 'api/im/single/remove',
      data: data,
      method: 'post'
  });
};

// 聊天内容
export const chatRecord = (data) => {
  return axios.request({
      url: 'api/im/record',
      data: data,
      method: 'post'
  });
};

// 消息已读
export const setRead = (data) => {
  return axios.request({
      url: 'api/im/read',
      data: data,
      method: 'post'
  });
};

// 当前用户聊天账号
export const myInfo = (data) => {
  return axios.request({
      url: 'api/im/info',
      data: data,
      method: 'post'
  });
};