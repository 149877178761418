<template>
  <div
    id="drag-contain"
    @mousedown="move"
    @touchstart="touchStart"
    @touchmove.prevent="touchMove"
    @touchend="touchEnd"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
    name: "Drag",
    props: {
      id: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        touchX: 0, //移动端点击时距左边的距离
        touchY: 0,
        odiv: "",
        timer: 0
      };
    },
    methods: {
      //点击事件
      click() {
      //判断是点击事件还是拖动事件
      },
      move(e) {
          e.stopPropagation(); //阻止事件传播
          // e.preventDefault(); //禁用默认事件，如点击
        //   let app = document.getElementById(this.id);
          let app = document.getElementById('kefu');
          if(app){
          let odiv = app; //获取目标元素
          //算出鼠标相对元素的位置
          let disX = e.clientX - odiv.offsetLeft;
          let disY = e.clientY - odiv.offsetTop ;

          this.timer = setTimeout(() => {
              this.timer = 0;
              //执行长按事件
          }, 1000);
          document.onmousemove = (e) => {
              //鼠标事件
              //鼠标按下并移动的事件
              //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
              let left = e.clientX - disX;
              let top = e.clientY - disY;
              if (left < 0) {
                  left = 0;
              }
              if (
                  left >
                  document.documentElement.clientWidth - odiv.offsetWidth
              ) {
                  //如果元素移动宽度超过屏幕可视高度则为屏幕高度
                  left =
                      document.documentElement.clientWidth - odiv.offsetWidth;
              } //判断与左边的距离不能超出屏幕可见区域外
              if (top < 0) {
                  top = 0;
              }
              if (
                  top >
                  document.documentElement.clientHeight - odiv.offsetHeight
              ) {
                  //如果元素移动高度超过屏幕可视高度则为屏幕高度
                  top =
                      document.documentElement.clientHeight -
                      odiv.offsetHeight;
              }

              //移动当前元素
              odiv.style.left = left + "px";
              odiv.style.top = top + "px";
          };
          document.onmouseup = (e) => {
              document.onmousemove = null;
              document.onmouseup = null;
              clearTimeout(this.timer);
              if (this.timer != 0) {
                  //执行单次点击事件
                  this.click();
              }
              return false;
          };

          return false;
          }
      },
      //手指按下
      touchStart(e) {
          // e.preventDefault();   //禁用默认事件比如点击

          e.stopPropagation(); //阻止事件传播
          let app = document.getElementById(this.id);
          if(app){
            this.odiv = app; //获取目标元素
            //算出鼠标相对元素的位置
            this.touchX = e.changedTouches[0].pageX - this.odiv.offsetLeft;
            this.touchY = e.changedTouches[0].pageY - this.odiv.offsetTop;
            this.timer = setTimeout(() => {
                this.timer = 0;
                //执行长按事件
            }, 1000);
            return false;
          }
      },
      //手指拖动事件
      touchMove(e) {
          //用手指的位置减去手指相对元素的位置，得到元素的位置
          let left = e.changedTouches[0].pageX - this.touchX;
          let top = e.changedTouches[0].pageY - this.touchY;

          if (left < 0) {
              left = 0;
          }
          if (left > window.innerWidth - this.odiv.offsetWidth) {
              left = window.innerWidth - this.odiv.offsetWidth;
          }
          if (top < 0) {
              top = 0;
          }
          if (top > window.innerHeight - this.odiv.offsetHeight) {
              top = window.innerHeight - this.odiv.offsetHeight;
          }


          let app1 = document.getElementsByClassName('contain')[0] ?document.getElementsByClassName('contain')[0].offsetTop: 0;
          
          let clientHeight = document.documentElement.clientHeight ?? 0;
          let app2 =  document.getElementsByClassName('van-tabbar')[0] ?document.getElementsByClassName('van-tabbar')[0].offsetHeight: 0
          // 
          if(top <app1 ){
            top = app1
          }
          if(top > clientHeight-(2*app2)){
            top = clientHeight-(2*app2)
          }
          //移动当前元素
          this.odiv.style.left = left + "px";
          this.odiv.style.top = top + "px";
      },
      //手指抬起
      touchEnd() {
          clearTimeout(this.timer);
          if (this.timer != 0) {
              //执行单次点击事件
              this.click();
          }
          return false;
      },
    },
};
</script>
<style lang="scss" scoped>
#drag-contain {
    position: fixed; 
    width: 17.333333vw;
    height: 17.333333vw;
}
</style>