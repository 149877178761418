import axios from 'axios'
import {Toast} from "vant"
import {setToken, wxInfoLogin, isApp} from '@/lib/utils'

const instance = axios.create({
    baseURL: process.env.NODE_ENV === "production" ? "/" : "/",
  })

// 添加请求拦截器
const loadWhiteList = ["api/orderStateByPay"] // loading白名单
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    if(loadWhiteList.indexOf(config.url) != -1) return config

    Toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
    })
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
// http://localhost:8081/#/pages/index/index?qycode=1001&qyname=%E4%BC%81%E4%B8%9A%E5%BF%99
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    Toast.clear();
    // 未登录或登录失效
    if (response.data.code == -99) {
        setToken(null)
        if (!isApp()) {
            Toast({
                message: '登录过期',
                onClose: res => {
                    wxInfoLogin('/')
                }
            })
        }
        return Promise.reject();
    }
    return response.data;
}, function (error) {
    console.log(error)
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    Toast.clear();
    if (error.response.status >= 500) {
        Toast.fail('系统错误')
    }
    return Promise.reject(error);
});

export default instance